import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Subject } from 'rxjs';
import { Settings } from '../interfaces/settings.interface';
import { TimeTrackingResources } from '../time-tracking.resource';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class TimeTrackingSettingsService {
    public subject: Subject<null> = new Subject();
    private cachedSettings: Settings | null = null;
    private getRequestPromise: Promise<any> | null = null;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.authService.onLogout.subscribe(() => {
            this.getRequestPromise = null;
            this.cachedSettings = null;
        });
    }

    /**
     * Set company settings
     */
    async set(settings: Omit<Settings, 'counts'>): Promise<void> {
        const payload = {
            data: {
                type: 'timeTracking.settings',
                attributes: {
                    settings,
                },
            },
        };

        const url = TimeTrackingResources.Settings;
        await this.http.put(url, payload).toPromise();

        // invalidate cached settings
        this.cachedSettings = null;
    }

    /**
     * Get company settings
     */
    async get(): Promise<Settings> {
        if (this.cachedSettings) {
            // deep clone to avoid changes to reference
            return JSON.parse(JSON.stringify(this.cachedSettings));
        }

        if (!this.getRequestPromise) {
            this.getRequestPromise = this.http.get(TimeTrackingResources.Settings).toPromise();
        }

        const response = await this.getRequestPromise;

        if (this.cachedSettings) {
            // deep clone to avoid changes to reference
            return JSON.parse(JSON.stringify(this.cachedSettings));
        }

        const settings = this.convertResponseToSettings(response);
        this.cachedSettings = settings;
        this.getRequestPromise = null;

        return settings;
    }

    /**
     * invalidate provides a way of telling the service that an external change
     * has made the settings no longer valid.
     *
     * Ex: something changed a count
     */
    invalidate(): void {
        this.cachedSettings = null;
        this.subject.next();
    }

    private convertResponseToSettings(response: any): Settings {
        const settingsData = response['data']['attributes']['settings'];
        const settings: Settings = { ...settingsData };

        // Some default values
        // Also, we coerce the string "" to false on boolean columns
        settings.noProjectApprovalFlow = settingsData.noProjectApprovalFlow ?? 'manager';
        settings.remindersWeeklyDeadline =
            settingsData.remindersWeeklyDeadline && Number(settingsData.remindersWeeklyDeadline) !== 0 ? true : false;
        settings.payrollIntegration =
            settingsData.payrollIntegration && Number(settingsData.payrollIntegration) !== 0 ? true : false;
        settings.allowFutureEntries =
            settingsData.allowFutureEntries && Number(settingsData.allowFutureEntries) !== 0 ? true : false;

        return settings;
    }
}
